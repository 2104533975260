(function($) {

    // Accordion for FAQs (jQuery)
    // $('.accordion').on('click', 'dt', function() {
    //     $(this).toggleClass('active').next().slideToggle();  
    // });  

    $('.gallery .slider').not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 600,
        arrows: false,
        fade: true,
        centerMode: true,
        infinite: true,
        lazyLoad: 'ondemand',
        asNavFor: '.gallery .slider-controls'
    });
    $('.gallery .slider-controls').not('.slick-initialized').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.gallery .slider',
        dots: false,
        arrows: false,
        lazyLoad: 'ondemand',
        focusOnSelect: true,
        infinit: true
    });
    
    var resizeTimer;
    $(window).bind('resize load', function () {

        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {

            $('.brands .slider').not('.slick-initialized').slick({
                infinite: true,
                mobileFirst: true,
                
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 600,
                autoplay: true,
                autoplaySpeed: 3000,
                centerMode: true,
                dots: true,
                arrows: false,
                mobileFirst: true,
                lazyLoad: 'ondemand',
                responsive: [
                    {
                        breakpoint: 760,
                        settings: 'unslick'
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    }
                ]
            });

            $('.work .slider').not('.slick-initialized').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 600,
                dots: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 5000,
                mobileFirst: true,
                lazyLoad: 'ondemand',
                prevArrow: $('.prev-w'),
                nextArrow: $('.next-w'),
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                ]
            });

        }, 500)
    })

    // Sticky Header
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 50) {
            $('header').addClass('scrolled');
        } else {
            $('header').removeClass('scrolled');
        }
    });

    // Smooth Scroll To Anchor
    $(document).on('click', '.js-cta', function (event) {
        event.preventDefault()
        var target = $(this).attr('href')

        if ($(target).length) {
            $('html, body').animate({
                scrollTop: $(target).offset().top - 100
            }, 1500)
        }
    });

    $(window).on('load', function () {

         $('.lazyload').Lazy({
             effect: 'fadeIn',
             visibleOnly: true,
             onError: function (element) {
                 console.log('error loading ' + element.data('src'));
             }
         });

        setTimeout( function() {
            $('.phone-text em').each(function () {
                var unsliced = $(this).text();
                var sliced = unsliced.slice(0, -2) + "...";
                $(this).text(sliced);
                var linked = "tel:" + unsliced.replace(/\s/g, '');
                $(this).click(function () {
                    if ($(window).width() < 1000) {
                        window.location.href = linked;
                        dataLayer.push({ 'event': 'gtm.PhoneClick' });
                    } else {
                        $(this).text(unsliced);
                        dataLayer.push({ 'event': 'gtm.PhoneClick' });
                    }
                });
            });

            if ($(window).width() < 1000) {
                $('.phone').click(function () {
                    dataLayer.push({ 'event': 'gtm.PhoneClick' });
                });
            }
        }, 3000)



    })


})( jQuery );

function getCurrentMonth() {
    var months    = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    var now       = new Date();
    var thisMonth = months[now.getMonth()]; // getMonth method returns the month of the date (0-January :: 11-December)
    var output = document.getElementsByClassName('output');

    $( ".output" ).html( thisMonth);
}
getCurrentMonth();